<template>
  <div id="unggas-nasional-data-produksi-hatchery-list" data-app>

    <!--begin::Engage Widget 1-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0 bg-white">
            <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center"
                style="background-position: right bottom; background-size: auto 100%; background-image: url(media/svg/humans/custom-8.svg)">

                <div class="row w-100">
                    <div class="col-12 col-sm-9">
                        <div class="row mb-2">
                            <b-dropdown size="sm" variant="link-primary" v-b-tooltip.hover title="Tambah Data" class="m-2">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-lg">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Shopping/Box3.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="font-weight-bolder font-size-lg">Tambah Data</span>
                                </template>
                                <b-dropdown-item  @click="openDialogForm('FormUploadSettingHE', null, true)">
                                    <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Communication/Incoming-box.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Upload Setting HE</span>
                                </b-dropdown-item>
                                <b-dropdown-item  @click="openDialogForm('FormUploadPullchick', null, true)">
                                    <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Communication/Incoming-box.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Upload Pullchick</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="separator separator-dashed separator-border-2 mb-5"></div>
                        <h4 class="text-danger font-weight-bolder">Search Hatchery</h4>
                        <div class="d-flex flex-center py-2 px-6 bg-light rounded">
                            <span class="svg-icon svg-icon-lg svg-icon-info">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                                <!--end::Svg Icon-->
                            </span>
							<input type="text" v-model="search" class="form-control border-0 font-weight-bold pl-2 bg-light" placeholder="Search Your Hatchery">
						</div>
                    </div>
                    <div class="col-12 col-sm-3">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Engage Widget 1-->

    <div v-if="!isBusy">
        <!--Begin::Row-->
        <b-row v-if="filteredItem.length">
            <b-col cols="12" lg="4" md="6" sm="6" 
                v-for="(val, key) in filteredItem" 
                :key="key" 
                class="py-0"
            >
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`"
                    @contextmenu.prevent="$refs.menu.open($event, val)"
                    @click="$router.push(`/unggas-nasional/produksi/${val._id}`)"
                >

                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <div class="flex-grow-1">
                            <!--begin::Info-->
                            <div class="d-flex align-items-center pr-2 mb-2">
                                <span class="text-dark font-weight-bolder text-hover-primary font-size-h4">
                                    Hatcery {{ val.hatchery }}
                                </span>
                                <span v-if="peringatan(val)" v-b-tooltip.hover :title="peringatan(val)" class="svg-icon svg-icon-lg svg-icon-danger ml-2 m-0">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                    <!--end::Svg Icon-->
                                </span>
                            </div>
                            <!--end::Info-->

                            <!--begin::Desc-->
                            <p class="text-dark-50 font-weight-normal font-size-sm mt-2">
                                Jumlah mesin {{ val.jumlah | nominal }} unit dengan kapasitas terpasang {{ (val.kapasitas) | nominal }} butir,
                                kapasitas terisi {{ val.setting_sum_jumlah | nominal }} butir
                            </p>
                            <!--end::Desc-->
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
        </b-row>
        <!--End::Row-->

        <div v-else class="card card-custom gutter-b mt-8" style="height: 150px">
            <!--begin::Body-->
            <div class="card-body d-flex align-items-center justify-content-between flex-wrap">
                <div class="mr-2">
                    <h3 class="font-weight-bolder">Tidak ada Hatchery</h3>
                    <div class="text-dark-50 font-size-lg mt-2">
                        Silahkan tambah data hatchery terlebih dahulu
                    </div>
                </div>
                <a @click="$router.push({ name: 'master-data-hatchery-add' })" class="cursor-pointer btn btn-primary font-weight-bold py-3 px-6">Tambah Hatchery</a>
            </div>
            <!--end::Body-->
        </div>
    </div>
    <div v-else>
        <!--Begin::Row-->
        <b-row>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
            <b-col cols="12" lg="4" md="6" sm="6" class="py-0">
                <!--begin::Stats--> 
                <div class="card card-custom bgi-no-repeat card-stretch gutter-b cursor-pointer"
                    :style="`background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/${getSVG()}`">
                    <!--begin::body-->
                    <div class="card-body d-flex flex-column">
                        <CodeLoader></CodeLoader>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Stats-->
            </b-col>
        </b-row>
        <!--End::Row-->
    </div>

    <v-dialog v-model="dialogDetail" :fullscreen="modal.fullscreen" :hideOverlay="modal.hideOverlay" :max-width="modal.width" persistent scrollable transition="dialog-bottom-transition">
        <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <vue-context ref="menu" v-slot="{ data }"
        class="navi navi-hover"
        :lazy="false"
    >
        <li class="navi-item">
            <a class="navi-link active" href="#">
                <span class="navi-icon"><i class="flaticon2-analytics"></i></span>
                <span class="navi-text">Messages</span>
                <span class="navi-label">
                    <span class="label label-danger label-rounded">2</span>
                </span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-pie-chart-2"></i></span>
                <span class="navi-text">{{ (data ? data.hatchery : null) }}</span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-layers"></i></span>
                <span class="navi-text">Profile</span>
            </a>
        </li>
        <li class="navi-item">
            <a class="navi-link" href="#">
                <span class="navi-icon"><i class="flaticon2-box-1"></i></span>
                <span class="navi-text">Tasks</span>
                <span class="navi-label">
                    <span class="label label-warning label-rounded">5</span>
                </span>
            </a>
        </li>
    </vue-context>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CodeLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import ErrorService from "@/core/services/error.service";

import FormUploadSettingHE from "@/view/pages/nasional/produksi/form/upload/_SettingHE.vue";
import FormUploadPullchick from "@/view/pages/nasional/produksi/form/upload/_Pullchick.vue";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "unggas-nasional-data-produksi-hatchery-list",
  components: {
    VueContext,
    CodeLoader,
    FormUploadSettingHE,
    FormUploadPullchick
  },  
  data() {
    return {
      search: '',
      isBusy: true,
      dialogDetail: false,
      curComponent: null,
      sdataForm: null,
      modal: {
        fullscreen: false,
        hideOverlay: false,
        width: '500px'
      },
      items: [],
      svg: ['abstract-1.svg', 'abstract-2.svg', 'abstract-3.svg', 'abstract-4.svg', 'abstract-5.svg'],
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    filteredItem() { 
        return this.items.filter((item) => item.hatchery.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    getSVG () {
        return this.svg[Math.floor(Math.random() * this.svg.length)];
    },
    peringatan (itm) {
        if(parseInt(itm.mesin_sum_kapasitas) < parseInt(itm.setting_sum_jumlah)) return "Hay.. jumlah kapasitas terisi kamu lebih besar dari kapasitas terpasang mungkin ada data yang tidak sesuai coba dicek ya.."

        return null
    },
    getData () {
      ProduksiService.getHatcheryList()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.items = []
                this.isBusy = false
                return;
            }
            this.items = res.data.data
            this.isBusy = false
        })
        .catch((err) => {
          this.items = []
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
    openDialogForm(name, data, fullpage = false) {
      this.modal.fullscreen = false
      this.modal.hideOverlay = false
      this.modal.width = '500px'

      if(fullpage) {
        this.modal.fullscreen = true
        this.modal.hideOverlay = true
        this.modal.width = null
      }

      this.curComponent = name
      this.sdataForm = data
      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res) {
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "UN Data Produksi" }]);
    if (!this.currentUser.menu.nasional_produksi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()    
  },
};
</script>

<style lang="scss">
#unggas-nasional-data-produksi-hatchery-list{
    .v-context{
        border: 0 solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 0.42rem !important;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
    }
}
</style>